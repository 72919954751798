import { mapKeys } from 'lodash-es'

export const ADMIN = 'admin'
export const TEAM_LEADER = 'teamLeader'
export const DEFINER = 'definer'
export const REVIEWER = 'reviewer'
export const JUNIOR_SCRAPER = 'juniorScraper'
export const MIDDLE_SCRAPER = 'middleScraper'
export const SENIOR_SCRAPER = 'seniorScraper'
export const DEFINER_JUNIOR_SCRAPER = 'definerJuniorScraper'
export const DEFINER_MIDDLE_SCRAPER = 'definerMiddleScraper'
export const DEFINER_MIDDLE_SCRAPER_REVIEWER = 'definerMiddleScraperReviewer'
export const DEFINER_SENIOR_SCRAPER_REVIEWER = 'definerSeniorScraperReviewer'
export const MIDDLE_SCRAPER_REVIEWER = 'middleScraperReviewer'
export const SENIOR_SCRAPER_REVIEWER = 'seniorScraperReviewer'
export const EXPORTER = 'exporter'
export const TRAINEE = 'trainee'
export const QUALITY_CHECKER = 'qualityChecker'
export const QUALITY_CHECKER_MIDDLE_SCRAPER = 'qualityCheckerMiddleScraper'
export const QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER =
  'qualityCheckerMiddleScraperReviewer'
export const QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER =
  'qualityCheckerSeniorScraperReviewer'
export const REPORTER = 'reporter'

export const ROLES = mapKeys(
  [
    { name: 'Admin', value: ADMIN },
    { name: 'Team leader', value: TEAM_LEADER },
    { name: 'Definer', value: DEFINER },
    { name: 'Reviewer', value: REVIEWER },
    { name: 'Junior scraper', value: JUNIOR_SCRAPER },
    { name: 'Middle scraper', value: MIDDLE_SCRAPER },
    { name: 'Senior scraper', value: SENIOR_SCRAPER },
    { name: 'Definer junior scraper', value: DEFINER_JUNIOR_SCRAPER },
    { name: 'Definer middle scraper', value: DEFINER_MIDDLE_SCRAPER },
    {
      name: 'Definer middle scraper reviewer',
      value: DEFINER_MIDDLE_SCRAPER_REVIEWER,
    },
    {
      name: 'Definer senior scraper reviewer',
      value: DEFINER_SENIOR_SCRAPER_REVIEWER,
    },
    { name: 'Middle scraper reviewer', value: MIDDLE_SCRAPER_REVIEWER },
    { name: 'Senior scraper reviewer', value: SENIOR_SCRAPER_REVIEWER },
    { name: 'Exporter', value: EXPORTER },
    { name: 'Trainee', value: TRAINEE },
    { name: 'Quality checker', value: QUALITY_CHECKER },
    {
      name: 'Quality checker middle scraper',
      value: QUALITY_CHECKER_MIDDLE_SCRAPER,
    },
    {
      name: 'Quality checker middle scraper reviewer',
      value: QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
    },
    {
      name: 'Quality checker senior scraper reviewer',
      value: QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
    },
    { name: 'Reporter', value: REPORTER },
  ],
  'value',
)
